import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  // name: 'ComponentName',
  props: {
    players: Array,
    user: Boolean,
    place: Number
  },
  data: function data() {
    return {};
  },
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    thumbStyle: function thumbStyle() {
      return {
        right: this.right,
        borderRadius: '2px',
        backgroundColor: '#fff',
        width: '2px',
        opacity: 1,
        zIndex: 4
      };
    },
    barStyle: function barStyle() {
      return {
        right: this.right,
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '1px',
        opacity: 0.1,
        zIndex: 4
      };
    }
  }
};