import { render, staticRenderFns } from "./TournamentTable.vue?vue&type=template&id=7ef6d79d&lang=pug&"
import script from "./TournamentTable.vue?vue&type=script&lang=js&"
export * from "./TournamentTable.vue?vue&type=script&lang=js&"
import style0 from "./TournamentTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QList,QBtn});
