import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import TournamentMixin from '@/mixins/TournamentMixin';
import TournamentTable from '@/components/Tournaments/TournamentTable'; // import TournamentTableHeader from '@/components/Tournaments/TournamentTableHeader';

import TournamentTimer from '@/components/Tournaments/TournamentTimer.vue';
import TournamentInfo from '@/components/Tournaments/TournamentInfo.vue';
export default {
  props: {
    tournament: Object,
    index: Number
  },
  components: {
    'app-leaders': TournamentTable,
    // 'app-leaders-header': TournamentTableHeader,
    'app-timer': TournamentTimer,
    'app-info': TournamentInfo
  },
  mixins: [TournamentMixin, Base],
  data: function data() {
    return {
      showMorePrizes: false,
      showItem: this.index === 0
    };
  }
};