//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VerificationSuccess',
  methods: {
    closeVerification: function closeVerification() {
      this.$emit('closeVerification');
    }
  }
};