import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    player: Object,
    user: Boolean,
    place: Number,
    isEndedTournament: {
      type: Boolean,
      default: false
    }
  }
};