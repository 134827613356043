//
//
//
//
//
export default {
  props: {
    tournament: Object
  },
  computed: {
    tournamentType: function tournamentType() {
      if (this.tournament.isActive || !this.tournament.isOpen) {
        return function () {
          return import(
          /* webpackChunkName: "app-started-tournament" */
          '@/components/Tournaments/StartedTournament.vue');
        };
      }

      return function () {
        return import(
        /* webpackChunkName: "app-future-tournament" */
        '@/components/Tournaments/FutureTournament.vue');
      };
    }
  }
};