//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    bonusData: Object
  },
  computed: {
    wagerStr: function wagerStr() {
      if (+this.bonusData.multiplier > 0) return this.bonusData.multiplier;
      if (+this.bonusData.freespin_multiplier > 0) return this.bonusData.freespin_multiplier;
      return null;
    }
  }
};