import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SumsubInfo from '@/components/Profile/SumsubInfo';
import VerificationSuccess from '@/components/Profile/VerificationSuccess';
import VerificationFailed from '@/components/Profile/VerificationFailed';
import SumsubVerify from '@modules/plugins/sumsubVerify/SumsubVerify';
import ProfileDocuments from '@/components/Profile/ProfileDocuments';
export default {
  name: 'Verification',
  components: {
    SumsubVerify: SumsubVerify,
    ProfileDocuments: ProfileDocuments,
    SumsubInfo: SumsubInfo,
    VerificationSuccess: VerificationSuccess,
    VerificationFailed: VerificationFailed
  },
  props: {
    autoVerificationData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isShowContent: false,
      showSumsubVerifyWidget: false,
      showSumsubInfo: true,
      isCloseVerify: false
    };
  },
  computed: {
    verificationStatus: function verificationStatus() {
      return this.$store.getters['player/verificationStatus'];
    },
    showSumsubVerify: function showSumsubVerify() {
      // && this.verificationStatus !== 'approved'
      return this.isAvailableSumsubVerify;
    },
    isAvailableSumsubVerify: function isAvailableSumsubVerify() {
      return this.autoVerificationData && this.autoVerificationData.isEnabled && this.autoVerificationData.showOn.includes('profile') && this.autoVerificationData.isAvailable;
    },
    isTestMode: function isTestMode() {
      var _this$autoVerificatio;

      return (_this$autoVerificatio = this.autoVerificationData) === null || _this$autoVerificatio === void 0 ? void 0 : _this$autoVerificatio.isTest;
    },
    getSumsubConfig: function getSumsubConfig() {
      return {
        uiConf: {
          customCssStr: '.iframe2.min-height {min-height:300px;}.sumsub-logo {display:none;}'
        }
      };
    },
    isApproved: function isApproved() {
      return this.verificationStatus === 'approved';
    },
    isRejected: function isRejected() {
      return this.verificationStatus === 'rejected';
    }
  },
  methods: {
    closeVerify: function closeVerify() {
      this.isCloseVerify = true;
    },
    showSumsub: function showSumsub() {
      this.showSumsubInfo = false;
    },
    switchShowContent: function switchShowContent() {
      this.isShowContent = !this.isShowContent;
    },
    setOpenContent: function setOpenContent() {
      var _this = this;

      this.isShowContent = true;
      this.showSumsubVerifyWidget = true;
      this.$nextTick(function () {
        _this.$store.$ui.scrollToElement('verification');
      });
    }
  },
  watch: {
    $route: function $route(_ref) {
      var hash = _ref.hash;
      if (hash.includes('verify')) this.setOpenContent();
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    if (this.$router.currentRoute.hash.indexOf('verify') > -1) {
      this.setOpenContent();
    }

    window.addEventListener('SHOW_FAILED_VERIFY', function () {
      _this2.$store.dispatch('player/setVerificationStatus', 'rejected');
    });
    window.addEventListener('SHOW_SUCCESS_VERIFY', function () {
      _this2.$store.dispatch('player/setVerificationStatus', 'approved');
    });
  }
};