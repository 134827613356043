import { render, staticRenderFns } from "./LevelItem.vue?vue&type=template&id=affbe918&scoped=true&lang=pug&"
import script from "./LevelItem.vue?vue&type=script&lang=js&"
export * from "./LevelItem.vue?vue&type=script&lang=js&"
import style0 from "./LevelItem.vue?vue&type=style&index=0&id=affbe918&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "affbe918",
  null
  
)

export default component.exports
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSlider,QItem,QBtn});
