import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PromotionItem from '@/components/Promotions/PromotionItem';
export default {
  components: {
    'app-promotion-item': PromotionItem
  },
  data: function data() {
    return {
      updatedLang: true,
      swiperOption: {
        loop: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        updateOnWindowResize: true,
        threshold: 1,
        // autoplay: {
        //   delay: 12500,
        //   disableOnInteraction: false,
        // },
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 350,
          modifier: 1,
          slideShadows: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {}
      }
    };
  },
  computed: {
    currentLang: function currentLang() {
      return this.$store.getters['lang/lang'];
    },
    bonuses: function bonuses() {
      return this.$store.getters['balance/bonuses'];
    },
    bonusesLength: function bonusesLength() {
      if (this.bonuses) return Object.keys(this.bonuses).length;
      return 0;
    },
    filteredBonuses: function filteredBonuses() {
      var _this = this;

      if (this.bonuses) {
        if (this.activeBonus) {
          return Object.values(this.bonuses).filter(function (b) {
            return b.id !== _this.activeBonus.id;
          });
        }

        return Object.values(this.bonuses);
      }

      return [];
    },
    activeBonus: function activeBonus() {
      return this.$store.getters['balance/activeBonus'];
    }
  },
  methods: {
    onSlide: function onSlide() {
      var realIndex = this.promotionSwiper.realIndex;
      var lastIndex = this.filteredBonuses.length - 1;

      if (realIndex === 0) {
        this.promotionSwiper.slideToLoop(0, 0, true);
      } else if (realIndex === lastIndex) {
        this.promotionSwiper.slideToLoop(realIndex, 0, true);
      }
    }
  },
  watch: {
    currentLang: function currentLang() {
      var _this2 = this;

      this.updatedLang = false;
      setTimeout(function () {
        _this2.updatedLang = true;
      }, 1300);
    },
    bonusesLength: function bonusesLength() {
      var _this3 = this;

      if (this.bonuses.length === 1) this.swiperOption.loop = false;else this.swiperOption.loop = true;
      this.$nextTick(function () {
        if (_this3.promotionSwiper) _this3.promotionSwiper.update();
      });
    }
  }
};