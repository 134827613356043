//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SimpleTimer from '@/mixins/SimpleTimer';
export default {
  mixins: [SimpleTimer],
  props: {
    tournament: Object
  },
  data: function data() {
    return {
      simple: true,
      date: {
        end: new Date(this.prize.expiredAt)
      }
    };
  },
  computed: {
    simpleTime: function simpleTime() {
      return this.parseTime();
    }
  },
  methods: {
    openGame: function openGame(slug) {
      var game = {
        id: slug,
        isMoney: true
      };
      this.$store.dispatch('game/getGame', game);
    }
  }
};