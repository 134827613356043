//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  // name: 'ComponentName',
  data: function data() {
    return {};
  }
};