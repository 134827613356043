//
//
//
//
//
//
//
//
//
//
import LevelItem from "./LevelItem";
export default {
  props: {
    documentStatus: String
  },
  components: {
    'app-level-item': LevelItem
  },
  computed: {
    screenWidth: function screenWidth() {
      return this.$q.screen.width;
    },
    currentLevel: function currentLevel() {
      return this.$store.getters['player/currentLevel'];
    },
    levelValue: function levelValue() {
      return this.currentLevel.currentCp;
    },
    levels: function levels() {
      return this.$store.getters['player/allLevels'];
    }
  }
};