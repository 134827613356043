//
//
//
//
//
//
//
export default {
  props: {
    right: {
      type: String,
      default: '0px'
    }
  },
  computed: {
    thumbStyle: function thumbStyle() {
      return {
        right: this.right,
        borderRadius: '2px',
        backgroundColor: '#353359',
        width: '2px',
        opacity: 0.75
      };
    },
    barStyle: function barStyle() {
      return {
        right: this.right,
        borderRadius: '4px',
        backgroundColor: '#000',
        width: '2px',
        opacity: 0.5
      };
    }
  }
};