import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ScrollWrapper from '@/components/Controls/ScrollWrapper';
import TournamentTableItem from '@/components/Tournaments/TournamentTableItem';
import TournamentLeaderBoardModal from '@/components/Tournaments/TournamentLeaderBoardModal';
export default {
  components: {
    'app-tournament-table-item': TournamentTableItem,
    'app-scroll': ScrollWrapper,
    'app-leader-modal': TournamentLeaderBoardModal
  },
  props: {
    players: Array,
    isEndedTournament: {
      type: Boolean,
      default: false
    },
    isShowAllPlayers: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isLeaderBoardModal: false
    };
  },
  computed: {
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    hasUser: function hasUser() {
      var _this = this;

      if (this.loggedIn && this.players) {
        return this.players.findIndex(function (p) {
          return p.uuid === _this.profile.uuid;
        }) > -1;
      }

      return false;
    },
    visiblePlayers: function visiblePlayers() {
      if (this.players) return this.isShowAllPlayers ? this.players : this.players.slice(0, 5);
      return null;
    }
  },
  methods: {
    scrollToPlayer: function scrollToPlayer() {
      var scrollArea = this.$refs.scroll.$children[0];
      var containerMiddle = scrollArea.containerHeight / 2;
      var userPosition = this.$refs[this.profile.uuid][0].$el.offsetTop;
      var position = userPosition - containerMiddle;
      if (userPosition <= containerMiddle) console.log('Player on top!');else scrollArea.setScrollPosition(position, 222);
    },
    openLeaderBoardModal: function openLeaderBoardModal() {
      this.isLeaderBoardModal = true;
    }
  },
  beforeUpdate: function beforeUpdate() {
    if (this.hasUser) this.scrollToPlayer();
  },
  mounted: function mounted() {
    if (this.hasUser) this.scrollToPlayer();
  }
};